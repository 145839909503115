export enum PopupTypeConstants {
    info = 'info',
    actionInfo = 'actionInfo',
    error = 'error',
    redirectToCart = 'redirectToCart',
    ShippingCharges = 'ShippingCharges',
    addressList = 'addressList',
    removeCoupon = 'removeCoupon',
    payment = 'payment',
    smallCartCharges = 'SmallCartCharges',
    shippingAndOtherCharges = 'ShippingAndOtherCharges'
}

export enum PopupHeadingContants {
    pwaInstallPrompt = 'Access Purplle easily by adding it to your Home Screen',
    errorPopup = "Error popup",
}

export enum PopupTextConstants {
    pwaInstallPrompt = 'Add Purplle-Lite to your Home Screen',
}

export enum PopupActionButtonNames {
    addToHomeScreen = 'Add to Home Screen',
    iAlreadyHaveApp = 'I already have the app',
}

export enum PopupActionButtonTypes {
    addToHomeScreenTriggered = 'add_to_homeScreen_triggered',
    iAlreadyHaveAppTriggered = 'i_already_have_the_app_triggered',
}

export enum PopupStyleClasses {
    primary = 'primary',
    secondary = 'secondary'
}